<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewCongressSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm(); $refs.refFormObserver.reset()"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Adicionar congresso
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="submit()"
          @reset.prevent="resetForm"
        >
          <!-- Imagem -->
          <validation-provider
            v-slot="{ errors }"
            name="image"
            rules="required"
          >
            <b-form-group
              label="Capa do congresso  "
              label-for="image"
            >
              <b-form-file
                id="image"
                v-model="form.image"
                placeholder="Selecione a capa..."
                drop-placeholder="Arraste seu arquivo para aqui..."
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Title -->
          <validation-provider
            v-slot="{ errors }"
            name="title"
            rules="required"
          >
            <b-form-group
              label="Título Congresso"
              label-for="title"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-input
                id="title"
                v-model="form.title"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider
            v-slot="{ errors }"
            name="description"
            rules="required"
          >
            <b-form-group
              label="Descrição do Congresso"
              label-for="description"
              :state="errors.length > 0 ? false : null"
            >
              <b-form-textarea
                id="description"
                v-model="form.description"
                placeholder="Digite a descrição do congresso"
                rows="3"
                max-rows="6"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Ready for sale -->
          <validation-provider
            v-slot="{ errors }"
            name="readyForSale"
            rules="required"
          >
            <b-form-group
              label="Pronto Para Venda?"
              label-for="readyForSale"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="readyForSale"
                v-model="form.readyForSale"
                :options="[{text:'Sim',value:'1'},{text:'Não', value: '0'}]"
                :reduce="option => option.value"
                label="text"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Ready for start -->
          <validation-provider
            v-slot="{ errors }"
            name="readyToStart"
            rules="required"
          >
            <b-form-group
              label="Pronto Para Iniciar?"
              label-for="readyToStart"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                id="readyToStart"
                v-model="form.readyToStart"
                :reduce="option => option.value"
                :options="[{text:'Sim',value:'1'},{text:'Não', value: '0'}]"
                label="text"
                :state="errors.length > 0 ? false : null"
              />

              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Price -->
          <validation-provider
            v-slot="{ errors }"
            name="price"
            rules="required|min_length_deposit:1"
          >
            <b-form-group
              label="Preço"
              label-for="price"
              :state="errors.length > 0 ? false : null"
            >
              <money
                id="price"
                v-model="form.price"
                v-bind="money"
                class="form-control"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="isBusy"
            >
              <b-spinner v-if="isBusy" />
              <template v-else>
                Adicionar
              </template>
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancelar
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, VBTooltip
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required, min_value as minValue } from 'vee-validate/dist/rules'
import Ripple from 'vue-ripple-directive'
import countries from '@/@fake-db/data/other/countries'
import CongressService from './congressService'
import { Money } from 'v-money'
import vSelect from 'vue-select'

extend('required', {
  ...required,
  message: 'Esse campo é obrigatório.',
})

extend('min_length_deposit', {
  ...minValue,
  message: 'O valor minímo para depositar é R$ 1,00.',
})

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    Money,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  props: {
    isAddNewCongressSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isBusy: false,
      countries,
      speaker_id: null,
      event_id: null,
      events: [],
      speakers: [],
      form: {
        image: null,
        title: null,
        description: null,
        price: 1.00,
        readyForSale: null,
        readyToStart: null,
      },
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        suffix: ' ',
        precision: 2,
        masked: false,
      }
    }
  },
  computed: {
    eventsState() {
      return this.$store.state.congressAdministrator.events
    },
    speakersState() {
      return this.$store.state.congressAdministrator.speakers
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    resetForm() {
      this.form = {
        image: null,
        title: null,
        description: null,
        price: 1.00,
        readyForSale: null,
        readyToStart: null
      }
    },
    submit() {
      this.$refs.refFormObserver.validate().then(async (success) => {
        if (success) {
          const formData = new FormData()
          const imagefile = document.querySelector('#image')
          formData.append('image', imagefile.files[0])
          formData.append('title', this.form.title)
          formData.append('description', this.form.description)
          formData.append('price', this.form.price)
          formData.append('readyForSale', this.form.readyForSale)
          formData.append('readyToStart', this.form.readyToStart)
          try {
            this.isBusy = true
            CongressService().sendCongress(formData).then((response) => {
              console.log(response)
              if (response === 400) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    icon: 'InfoIcon',
                    title: 'Erro',
                    text: 'ERROR: erro ao tentar gravar as informações',
                    variant: 'danger',
                  }
                })
              } else if (response === 200) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Operação efetuada com sucesso.',
                    icon: 'InfoIcon',
                    variant: 'success',
                  }
                })
                this.$emit('close')
                this.$emit('onSaved', true)
              }
            }).catch((err) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  icon: 'InfoIcon',
                  title: 'Erro ao tentar gravar as informações',
                  variant: 'danger',
                }
              })
            })
              .finally(() => { this.isBusy = false })
          } catch (err) {
            this.$toast({
              component: ToastificationContent,
              props: {
                icon: 'InfoIcon',
                title: err.message,
                variant: 'danger',
              }
            })
          }
        }
      })
    },
    sortByName(a, b) {
      if (a.text < b.text) {
        return -1
      }
      if (a.text > b.text) {
        return 1
      }
      return 0
    },
    sortByDate(a, b) {
      if (a.date < b.date) {
        return -1
      }
      if (a.date > b.date) {
        return 1
      }
      return 0
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-congress-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
